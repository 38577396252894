import menuTranslates from './menu-i18n.json';

export default function () {
  return {
    ...menuTranslates,

    termsUser: {
      ru: 'Пользовательское соглашение',
      ua: 'Угода користувача'
    },
    linkToRegistrationConditionTitle: {
      ru: 'Условия регистрации',
      ua: 'Умови реєстрації'
    },
    linkToRegistrationConditionLabel: {
      ru: 'Политика обработки персональных данных',
      ua: 'Політика обробки персональних даних'
    },

    linkToVintageTitle: {
      ru: 'VINTAGE - СОЗДАТЕЛИ ЛУЧШИХ САЙТОВ В МИРЕ',
      ua: 'VINTAGE - ТВОРЦІ КРАЩИХ САЙТІВ В СВІТІ'
    }
  };
}
