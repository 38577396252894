import React from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import SideMenu, {
  UserMenu,
  UserNavMenu
} from 'components/header/side-menu';
import { Menu, Account } from 'components/header';
import Button from 'components/button';

import translates from './header-i18n';

import 'styles/bildcontrol/body/header/_side-menu.scss';

const classes = new Bem('sideMenu');

@I18nHoc(translates())
export default class BildControlSideMenu extends SideMenu {
  static displayName = 'BildControlSideMenu';

  static propTypes = {
    ...SideMenu.propTypes,
    renderSearch: PropTypes.func
  };

  renderUserMenu() {
    const {
      auth, desktop, onMenuClose,
      checkPermissions, checkPermission, actionAuthLogout
    } = this.props;

    return (
      <UserMenu
        userData={auth}
        desktop={desktop}
        onCloseSideMenu={onMenuClose}
      >
        <div className="userMenu__content">
          <UserNavMenu
            checkPermissions={checkPermissions}
            checkPermission={checkPermission}
            actionAuthLogout={actionAuthLogout}
            onCloseSideMenu={onMenuClose}
          />
        </div>
      </UserMenu>
    );
  }

  renderMainMenu() {
    const { i18n } = this;
    const {
      mainMenu, onMenuClose,
      actionAuthLogout, onOpenMenu, auth = {}
    } = this.props;

    return (
      <>
        <Menu
          mainMenu={mainMenu}
          onCloseSideMenu={onMenuClose}
          mobile
        />
        {!auth.authenticated &&
          <Account
            onAccountClick={onOpenMenu}
          />
        }
        {auth.authenticated &&
          <Button
            {...classes('btn', 'exit')}
            onClick={actionAuthLogout}
            label={i18n('btnLogout')}
            title={i18n('btnLogout')}
          />
        }
      </>
    );
  }

  renderSearchTouch() {
    if (this.props.renderSearch) return this.props.renderSearch();
  }
}
