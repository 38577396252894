import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { routerShape } from 'react-router';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import { connect } from 'react-redux';
import { authConnect, UserProp } from 'auth';
import {
  withSideMenu,
  MenuTopBtn,
  LogoHeader,
  Menu,
  FavoritesLink,
  serverMenu,
  Account
} from 'components/header';
import SearchIndex from 'components/search-index';
import Icon, { iconTypes } from 'components/icon';
import Button from 'components/button';
import Accordion from 'components/accordion';

import links from 'src-sites/bildcontrol/links';
import SideMenu from './side-menu';

import translates from './header-i18n';

export const bildcontrolMenu = [
  {
    builder: links.sectionAbout,
    name: 'aboutUs'
  },
  {
    builder: links.sectionLanding,
    contentType: 'flatsNewbuild'
  },
  {
    builder: links.sectionHowWeCalculateScore,
    name: 'howWeCalculateScore'
  },
  {
    builder: links.sectionContacts,
    name: 'contacts'
  }
];
const classes = new Bem('headerContent');
const bemClassesSearchInput = new Bem('searchInput');
const bemClassesAccordion = new Bem('headerAccordion');
const bemClassesAccount = new Bem('account');

@authConnect()
@withSideMenu
@connect(state => ({ notification: state.notification }))
@I18nHoc(translates())
export default class BildcontrolHeaderContent extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    mainMenu: PropTypes.shape({}),
    activeRoute: PropTypes.shape({}),

    // Says which device is now active:
    desktop: PropTypes.bool, // desktop
    phone: PropTypes.bool, // phone

    // Auth props
    auth: UserProp,
    actionAuthLogout: PropTypes.func,
    checkPermission: PropTypes.func,
    checkPermissions: PropTypes.func,

    // Close sideMenu method
    onCloseMenu: PropTypes.func,

    // Open sideMenu method
    onOpenMenu: PropTypes.func,

    // Says whether user clicked account button
    accountClicked: PropTypes.bool,

    // Says sideMenu is opened
    menuOpened: PropTypes.bool,
  };

  static contextTypes = {
    router: routerShape
  };

  static renderNoAuthContent() {
    return (
      <Icon
        {...bemClassesAccount('icon')}
        type={iconTypes.person}
      />
    );
  }

  constructor(props, ctx) {
    super(props, ctx);

    this.renderSearch = this.renderSearch.bind(this);
    this.searchItemSelected = this.searchItemSelected.bind(this);
    this.showMobileSearch = this.showMobileSearch.bind(this);

    this.router = ctx.router;
  }

  searchItemSelected(it) {
    if (!this.props.desktop) this.accordRef.toggle();
    const link = links.sectionLanding(it).href;
    if (link) this.router.push(links.sectionLanding(it).href);
  }

  showMobileSearch() {
    this.accordRef.toggle();
  }

  renderLogoContent(logoClasses) {
    return (
      <>
        <Icon {...logoClasses('icon', 'icon')} type={iconTypes.logoBildControl} />
        <Icon {...logoClasses('icon', 'logo')} type={iconTypes.logoBildControlText} />
      </>
    );
  }

  renderSearch() {
    return (
      <SearchIndex
        {...classes('search')}
        iconPlaceholder=""
        placeholder={this.i18n('searchPlaceholder')}
        queryParams={{
          landing: true,
          entities: ['newbuild'],
          order: [['relevancy', 'desc']]
        }}
        linkBuilder={links.sectionLanding}
        onItemSelected={this.searchItemSelected}
        searchBoxContent={<Icon {...bemClassesSearchInput('icon', 'search')} type={iconTypes.searchBild} />}
        noError
      />
    );
  }

  renderAccordion(type) {
    return (
      <Accordion
        {...bemClassesAccordion()}
        ref={el => this.accordRef = el}
        noHeaderButton
      >
        <div {...bemClassesAccordion('content')} children={type === 'search' && this.renderSearch()} />
      </Accordion>
    );
  }

  render() {
    const { i18n } = this;
    const {
      accountClicked,
      desktop, phone,
      onOpenMenu, onCloseMenu, menuOpened,
      auth,
      actionAuthLogout,
      checkPermission, checkPermissions,
      className, mainMenu, activeRoute
    } = this.props;
    const sideMenuProps = { socialsAndPhones: false };
    const builtMenu = serverMenu(bildcontrolMenu, mainMenu, i18n);

    return (
      <div {...classes({ extra: className })}>
        {!desktop &&
          <MenuTopBtn onOpenSideMenu={onOpenMenu} />
        }
        <LogoHeader
          linkBuilder={links.sectionBildControl}
          builderProps={{ title: 'bildcontrol.ua' }}
          renderContent={this.renderLogoContent}
        />
        {desktop &&
          <>
            {this.renderSearch()}
            <Menu
              {...classes('menu')}
              mainMenu={builtMenu}
            />
          </>
        }
        {!desktop &&
          <Button
            {...classes('btn', 'search accordion')}
            onClick={this.showMobileSearch}
            iconOnly
            customRenderer
          >
            <span {...classes('btnContent')}>
              <Icon
                {...classes('icon', 'search')}
                type={iconTypes.searchBild}
              />
            </span>
          </Button>
        }
        <FavoritesLink
          builder={links.pageFavorites}
          builderProps={{ project: 'bildcontrol' }}
          otherPropsIcon={{
            type: iconTypes.favoritesBorder
          }}
        />
        {desktop &&
          <Account
            {...classes('account')}
            onAccountClick={onOpenMenu}
            renderContent={BildcontrolHeaderContent.renderNoAuthContent}
          />
        }
        <SideMenu
          auth={auth}
          actionAuthLogout={actionAuthLogout}
          desktop={desktop}
          phone={phone}
          accountClicked={accountClicked}
          menuOpened={menuOpened}
          onMenuClose={onCloseMenu}
          mainMenu={builtMenu}
          activeRoute={activeRoute}
          checkPermission={checkPermission}
          checkPermissions={checkPermissions}
          {...sideMenuProps}
        />
        {!desktop && this.renderAccordion('search')}
      </div>
    );
  }
}
