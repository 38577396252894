import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Bem from 'bemHelper';
import sensor from 'components/sensor';
import Container from 'components/container';
import { changeDevice } from 'components/view-map-search/view-search';

import BildcontrolHeaderContent from './header-content';

import 'styles/bildcontrol/body/header/_header.scss';

const classesHeader = new Bem('header');

@sensor
export default class Header extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    mainMenu: PropTypes.shape({}),
    activeRoute: PropTypes.shape({})
  };

  constructor(...props) {
    super(...props);
    this.onHandleResize = this.onHandleResize.bind(this);
    this.onResize = this.onHandleResize;
    this.state = {
      device: 'desktop'
    };
  }

  componentDidMount() {
    this.onHandleResize(this.sensor.getRuntime());
  }

  onHandleResize({ media: { desktop, tablet, phone } = {} }) {
    changeDevice.call(this, desktop, tablet, phone);
  }

  render() {
    const { className, mainMenu, activeRoute } = this.props;
    const { device } = this.state;

    return (
      <header {...classesHeader({ extra: className })}>
        <Container {...classesHeader('content')}>
          <div {...classesHeader('body')}>
            <BildcontrolHeaderContent
              mainMenu={mainMenu}
              desktop={device === 'desktop'}
              phone={device === 'phone'}
              activeRoute={activeRoute}
            />
          </div>
        </Container>
      </header>
    );
  }
}
