import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Bem from 'bemHelper';
import { I18nHoc, LangContext } from 'helpers/i18n';
import appMessage from 'helpers/appMessage';
import preload from 'api/data-preload';
import DataProvider from 'api/data-provider';
import GeoLandings from 'subsys/geo-landings';
import ManagementToolbar from 'subsys/management-toolbar';
import Container from 'components/container';
import Row, { Col } from 'components/row';
import LoginModal from 'components/login-modal';
import { BannerAffix } from 'components/banners';
import { checkUserLang } from 'components/lang-switch';

import HelmetI18n from './helmet-i18n';
import Header from './header/header';
import Footer from './footer';

import config from '../../../config';

import translates from './body-i18n.json';

import 'styles/bildcontrol/body/_body.scss';

const bemClasses = new Bem('body');

@preload({
  mainMenuBase: [DataProvider.preload, {
    url: 'core/menu',
    cache: 60 * 24,
    query: {
      project: 'bildcontrol'
    }
  }]
}, { noReload: true })
// TODO Debug and refactor this @geolocationConnect()
@I18nHoc(translates)
export default class BildControlBody extends Component {
  static propTypes = {
    router: PropTypes.shape({
      location: PropTypes.shape({
        pathname: PropTypes.string
      })
    }),
    location: PropTypes.shape({}),
    routes: PropTypes.arrayOf(PropTypes.shape({})),
    mainMenuBase: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    children: PropTypes.node,
  };

  static selectors = ['html', 'body'];

  componentDidMount() {
    if (__APP__) appMessage({ render: 'done' });
    checkUserLang.call(this);
  }

  render() {
    const { i18n } = this;
    const { routes = [], mainMenuBase, children, location } = this.props;
    const activeRoute = routes[routes.length - 1];
    activeRoute['query'] = get(this, 'props.router.location.query');
    activeRoute['page'] = get(this, 'props.router.params.page');
    const onlySocialAuth = config.env !== 'local';

    return (
      <LangContext activeRoute={activeRoute}>
        <Row {...bemClasses(null, activeRoute.mods)} allColumn noMaxW>
          <Col {...bemClasses('content')} allFlex allPr={0}>
            <HelmetI18n location={location} />
            <Row allColumn hFull noMaxW>
              <Col {...bemClasses('mainContainer')} allFlex allPr={0}>
                <div {...bemClasses('headerContainer')}>
                  <ManagementToolbar />
                  <GeoLandings
                    injectPropName="mainMenu"
                    base={mainMenuBase}
                  >
                    <Header activeRoute={activeRoute} />
                  </GeoLandings>
                </div>
                <Container {...bemClasses('main')} body>
                  {React.Children.map(children, child =>
                    React.cloneElement(child, { ...child.props, mainMenuBase })
                  )}
                </Container>
              </Col>
            </Row>

            <GeoLandings
              base={{}}
            >
              <BannerAffix activeRoute={activeRoute} />
            </GeoLandings>

            <GeoLandings
              injectPropName="mainMenu"
              base={mainMenuBase}
            >
              <Footer
                {...bemClasses('footer')}
                activeRoute={activeRoute}
              />
            </GeoLandings>

            <LoginModal onlySocialAuth={onlySocialAuth} />

            <noscript {...bemClasses('noJS')} children={i18n('noscript')} />
          </Col>
        </Row>
      </LangContext>
    );
  }
}
