import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { JsonLd } from 'react-schemaorg';
import map from 'lodash/map';
import Bem from 'bemHelper';
import { I18nHoc } from 'helpers/i18n';
import absoluteUrl from 'helpers/absoluteUrl';
import Row, { Col } from 'components/row';
import Link from 'components/link';
import Icon, { iconTypes } from 'components/icon';
import { serverMenu } from 'components/header';
import LangSwitch from 'components/lang-switch';

import { renderLink } from 'src-sites/bild/body/footer-link';
import defaultLogoBildControl from 'src-sites/bildcontrol/assets/logo-bildcontrol.jpg';
import linksBildControl from 'src-sites/bildcontrol/links';

import { bildcontrolMenu } from './header/header-content';

import translates from './footer-i18n';

import 'styles/bildcontrol/body/_footer.scss';

const classesFooter = new Bem('footer');

@I18nHoc(translates())
export default class Footer extends Component {
  static propTypes = {
    className: PropTypes.string,
    mainMenu: PropTypes.shape({}),
  };

  static componentCacheConfig = {
    strategy: 'template',
    enable: true
  };

  render() {
    const { i18n } = this;
    const { mainMenu, className, activeRoute  } = this.props;
    const footerMenu = serverMenu(bildcontrolMenu, mainMenu, i18n);
    const name = 'BildControl.ua';
    const urlFB = '//www.facebook.com/bildcontrol';
    const dateYear = `2019-${(new Date()).getFullYear()}`;

    return (
      <footer {...classesFooter({ extra: className })}>
        <Row {...classesFooter('row', 'block')} mpColumn>
          <Col {...classesFooter('col', 'menu')} allFlex allPr={0}>
            {map(footerMenu, (item, index) => (
              <div {...classesFooter('item')} key={index}>
                <Link {...classesFooter('link')} {...item}>
                  <span {...classesFooter('linkContent')}>
                    <span {...classesFooter('text')} children={item.name} />
                  </span>
                </Link>
              </div>
            ))}
            {renderLink.call(null, linksBildControl.sectionTerms, i18n('termsUser'), i18n('termsUser'), { rel: '', noIndex: false })}
            {renderLink.call(null, linksBildControl.sectionRegistrationCondition, i18n('linkToRegistrationConditionTitle'), i18n('linkToRegistrationConditionLabel'), { rel: '', noIndex: false })}
          </Col>
          <Col {...classesFooter('col', 'menu')} allFlex allAuto allPr={0}>
            <div {...classesFooter('item')}>
              <LangSwitch activeRoute={activeRoute} />
            </div>
          </Col>
          <Col {...classesFooter('col', 'menu')} allFlex allAuto allPr={0}>
            <div {...classesFooter('item')}>
              <Link
                {...classesFooter('link', 'social fb')}
                href={urlFB}
                title="facebook.com"
                rel="nofollow"
                noRedirector
                external
                blank
              >
                <span {...classesFooter('linkContent')}>
                  <Icon {...classesFooter('icon')} type={iconTypes.socialFacebook} />
                </span>
              </Link>
            </div>
          </Col>
          <Col {...classesFooter('col', 'copy')} allFlex allAuto allPr={0}>
            <div {...classesFooter('item', 'copyContainer')}>
              <span {...classesFooter('text')}>&copy; {dateYear}</span>
            </div>
            <div {...classesFooter('item', 'logoV')}>
              <Link
                {...classesFooter('link', 'noUnderline')}
                href="//vintage.com.ua"
                title={i18n('linkToVintageTitle')}
                rel="nofollow"
                external
                blank
              >
                <span {...classesFooter('linkContent')}>
                  <span {...classesFooter('text', 'logoV')} children="by" />
                  <Icon {...classesFooter('icon', 'logoV')} type={iconTypes.logoV} />
                </span>
              </Link>
            </div>
          </Col>
        </Row>
        <JsonLd
          item={{
            '@context': 'https://schema.org',
            '@type': 'Organization',
            logo: absoluteUrl(defaultLogoBildControl),
            name,
            url: absoluteUrl(''),
            foundingDate: dateYear,
            sameAs: urlFB
          }}
        />
      </footer>
    );
  }
}
